import * as PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { navigate } from 'gatsby-plugin-intl'

import { withMemo, withWebsiteOpen } from '../../decorators'
import { useIntl } from '../../intl/intl'
import LayoutGame from '../../components/LayoutGame'
import GameErrorTemplate from '../../templates/GameError'
import routes from '../../router/routes'
import useConfiguration from '../../hooks/useConfiguration'
import * as appConfiguration from '../../configuration'


const NotFound = (props) => {
  const { pageContext, location } = props
  const t = useIntl()
  const { seoFallback } = useConfiguration()

  const layoutProps = useMemo(() => ({
    withHeader: false,
    seo: {
      lang: pageContext?.language ?? null,
      title: seoFallback?.title ?? '',
      description: seoFallback?.description?.description ?? '',
      image: appConfiguration.app.url + seoFallback?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
      url: location?.href ?? '',
      robots: 'noindex, follow',
    },
  }), [location.href, pageContext.language, seoFallback.description.description, seoFallback.picture.localFile.childImageSharp.fluid.src, seoFallback.title])

  const startNavProps = {
    buttons: [
      {
        text: t('go_back'),
        variant: 'bigtransparent',
        onClick: () => {
          navigate(routes.home.path)
        },
      },
    ],
  }

  const gamePageErrorProps = {
    title: t('not_found'),
    text: t('not_found_text'),
  }

  return (
    <LayoutGame
      {...layoutProps}
    >
      <GameErrorTemplate
        startNavProps={startNavProps}
        {...gamePageErrorProps}
      />
    </LayoutGame>
  )
}

NotFound.propTypes = {
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}

NotFound.defaultProps = {
  pageContext: null,
  location: null,
}

export default withWebsiteOpen(withMemo()(NotFound))
